import Image from "next/image";
import { useState } from "react";
import dynamic from "next/dynamic";

import PlayButton from "@components/PlayButton";
const VideoModal = dynamic(() => import("@components/VideoModal"));
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

import clsx from "clsx";

const VideoEmbed = ({
	url,
	backgroundImage,
	onClose,
	backgroundImageBlur,
	videoStyle = "inline",
	autoPlay = false,
}) => {
	const [isPlaying, setIsPlaying] = useState(
		autoPlay || videoStyle === "autoplayInline"
	);

	//console.log(videoStyle)
	//console.log(isPlaying)

	const [isVideoOpen, setIsVideoOpen] = useState(false);

	const handleModalPlayButtonClick = () => {
		setIsVideoOpen(true);
	};

	return (
		<>
			<div className={clsx("VideoEmbed", "relative")}>
				<div
					className={clsx(
						"VideoEmbed__inlineContainer",
						"relative",
						"max-w-screen-xl mx-auto"
					)}
				>
					<div
						className={clsx(
							"VideoEmbed__playerWrapper",
							"aspect-w-16 aspect-h-9 relative"
						)}
					>
						<ReactPlayer
							url={url}
							playsinline
							width="100%"
							height="100%"
							playing={isPlaying}
							muted={
								(autoPlay &&
									videoStyle !== "inModal" &&
									videoStyle !== "slideDown") ||
								videoStyle === "autoplayInline"
							}
							config={{
								wistia: {
									options: {
										playerColor: "0D1418",
										controlsVisibleOnLoad: false,
										copyLinkAndThumbnailEnabled: false,
										playButton: false,
										wmode: "transparent",
										fitStrategy: "contain",
										chromeless: true,
									},
								},
							}}
							className={clsx("VideoEmbed__player")}
							onPlay={() => setIsPlaying(true)}
							onPause={() => setIsPlaying(false)}
						/>
					</div>
					{!isPlaying && <PlayButton onClick={() => setIsPlaying(true)} />}
					{videoStyle === "inModal" && (
						<button
							type="button"
							className={clsx(
								"absolute",
								"top-0",
								"right-0",
								"bg-blue-01 text-white-01",
								"h-12 px-5 font-bold"
							)}
							onClick={onClose}
						>
							X
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default VideoEmbed;
