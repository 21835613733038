import clsx from "clsx";

import ContentBlock from "@components/ContentBlock";
import HeadingTag from "@components/HeadingTag";
import HighlightedString from "@components/HighlightedString";
import SecondaryButton from "@components/SecondaryButton";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import Image from "next/image";
import { useEffect, useState } from "react";

const ContentBlocks = ({
	heading,
	headingTag = "h2",
	headingSize = "lg",
	blocks,
	columns = 2,
	fullWidth = false,
	layout = "vertical",
	textColor = "currentColor",
	padding = false,
	borderColor = "transparent",
	highlightColor = "currentColor",
	centerColumns = false,
	addGap = false,
	verticalPadding = "none",
	target,
	legacyButtonBackgroundColor,
	buttonTextColor,
	subHeading,
	legacyBackgroundColor,
	backgroundImage,
	backgroundImageBlur,
	iconColor,
}) => {
	const hasVisibleBorders = !!borderColor && borderColor !== "transparent";
	const [hover, setHover] = useState({});
	const isHovered = {};
	return (
		<div className={clsx("ContentBlocks", "relative")}>
			<div
				className={clsx("ContentBlocks__wrapper", "mx-auto", "relative z-10", {
					"max-w-screen-xl": !fullWidth,
					"max-w-screen-2xl": fullWidth,
					"py-7.5": verticalPadding === "sm",
					"py-7.5 lg:py-15": verticalPadding === "md",
					"py-15 lg:py-30": verticalPadding === "lg",
					"py-30 lg:py-60": verticalPadding === "xl",
				})}
				style={{ color: textColor, backgroundColor: legacyBackgroundColor }}
			>
				{!!heading && (
					<HeadingTag
						tag={headingTag}
						className={clsx(
							"ContentBlocks__heading",
							"max-w-screen-xl",
							"px-7.5 md:px-15",
							"text-center font-medium",
							{
								"text-4xl md:text-7xl lg:text-7xl": headingSize === "xxxl",
								"text-4xl md:text-6xl lg:text-6xl": headingSize === "xxl",
								"text-3xl lg:text-4xl 3xl:text-4xl":
									!headingSize || headingSize === "xl",
								"text-2xl md:text-3xl lg:text-3xl": headingSize === "lg",
								"text-2xl": headingSize === "md",
								"text-xl": headingSize === "sm",
								"mb-7.5 lg:mb-15": !subHeading,
								"mb-5": subHeading,
							}
						)}
					>
						<HighlightedString string={heading} color={highlightColor} />
					</HeadingTag>
				)}

				{subHeading && (
					<HeadingTag
						tag="h3"
						className={clsx(
							"ContentBlocks__subHeading",
							"text-xl text-center px-5",
							{ "mb-7.5 lg:mb-15": subHeading }
						)}
					>
						{subHeading}
					</HeadingTag>
				)}
				<div
					className={clsx("ContentBlocks__blocks", "sm:grid mx-5", {
						"gap-5": !hasVisibleBorders,
						"gap-10": addGap,
						"sm:grid-cols-1": columns > 1,
						"md:grid-cols-1": columns === 1,
						"md:grid-cols-2": columns === 2,
						"md:grid-cols-3": columns === 3,
						"md:grid-cols-4": columns === 4,
						"md:grid-cols-5": columns === 5,
						"justify-center": centerColumns,
						"justify-end w-full": blocks[0].constrainToContainer && fullWidth,
						"border-l border-t border-transparent":
							hasVisibleBorders && !addGap,
					})}
					style={{ borderColor: borderColor }}
				>
					{blocks.map((block, index) => {
						const icon = !!block.icon ? block.icon[0] : null;
						const image = !!block.image ? block.image[0] : null;
						const backgroundImage = !!block.backgroundImage
							? block.backgroundImage[0]
							: null;
						const mobileImage = !!block.mobileImage
							? block.mobileImage[0]
							: null;
						const mobileImageBlur = !!block.mobileImageBlur
							? block.mobileImageBlur[0]
							: null;
						const imageBlur = !!block.imageBlur ? block.imageBlur[0] : null;
						const backgroundImageBlur = !!block.backgroundImageBlur
							? block.backgroundImageBlur[0]
							: null;

						isHovered[index] = false;

						const blockStyle = (hover) => ({
							color:
								block.target?.url && hover && block.hoverBackgroundColor
									? block.hoverTextColor
									: block.textColor,
							legacyBackgroundColor:
								block.target?.url && hover && block.hoverBackgroundColor
									? block.hoverBackgroundColor
									: block.legacyBackgroundColor
									? block.legacyBackgroundColor
									: "transparent",
							borderColor:
								block.target?.url && hover && block.hoverBackgroundColor
									? block.hoverBackgroundColor
									: borderColor,
						});

						return (
							<div
								key={block.id}
								className={clsx(
									"ContentBlocks__block transition ease-in-out delay-0",
									{
										"md:col-span-2":
											block.spanColumns && block.spanXColumns == 2,
										"md:col-span-3":
											block.spanColumns && block.spanXColumns == 3,
										"md:col-span-4":
											block.spanColumns && block.spanXColumns == 4,
										"md:col-span-full":
											block.spanColumns && block.spanXColumns == "full",
										"border-r border-b border-transparent":
											!!borderColor && borderColor !== "transparent" && !addGap,
										"border-0": !borderColor || borderColor === "transparent",
										"max-w-xl": fullWidth && block.constrainToContainer,
										"mb-5 md:mb-0 border-[6px]": addGap && borderColor,
									}
								)}
								style={blockStyle(hover[index])}
								onPointerOver={() => {
									(isHovered[index] = true), setHover(isHovered);
								}}
								onPointerOut={() => {
									(isHovered[index] = false), setHover(isHovered);
								}}
							>
								<ContentBlock
									icon={icon}
									heading={block.heading}
									headingColor={block.headingColor}
									headingSize={block.headingSize}
									text={block.text}
									footnote={block.footnote}
									target={block.target}
									targetColor={block.targetColor}
									legacyButtonBackgroundColor={
										block.legacyButtonBackgroundColor
									}
									buttonHoverBackgroundColor={block.buttonHoverBackgroundColor}
									buttonHoverTextColor={block.buttonHoverTextColor}
									secondaryTarget={block.secondaryTarget}
									secondaryTargetColor={block.secondaryTargetColor}
									centerColumns={centerColumns}
									layout={layout}
									textColor={block.textColor}
									legacyBackgroundColor={block.legacyBackgroundColor}
									padding={padding}
									logos={block.logos}
									textAlignment={block.textAlignment}
									borderColor={borderColor}
									hoverBackgroundColor={block.hoverBackgroundColor}
									image={image}
									mobileImage={mobileImage}
									mobileImageBlur={mobileImageBlur}
									imageBlur={imageBlur}
									spanColumns={block.spanColumns}
									backgroundImage={backgroundImage}
									backgroundImageBlur={backgroundImageBlur}
									showButtonBorder={block.showButtonBorder}
									isHovered={hover[index]}
									iconColor={iconColor}
								/>
							</div>
						);
					})}
				</div>
				{target && target?.url && (
					<div
						className={clsx(
							"ContentBlocks__footer",
							"flex justify-center pt-7.5 md:pt-15 px-5"
						)}
					>
						<SecondaryButton
							target={target}
							legacyBackgroundColor={legacyButtonBackgroundColor}
							textColor={buttonTextColor}
						/>
					</div>
				)}
			</div>
			{!!backgroundImage?.url && (
				<div
					className={clsx(
						"ContentBlocks__backgroundImage",
						"absolute inset-0 z-0",
						"mx-auto max-w-screen-3xl"
					)}
				>
					<Image
						src={backgroundImage.url}
						layout="fill"
						objectFit="cover"
						alt={backgroundImage.title}
						draggable={false}
						{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
					/>
				</div>
			)}
		</div>
	);
};

export default ContentBlocks;
