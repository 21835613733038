import clsx from "clsx";
import Image from "next/image";
import { useMeasure } from "react-use";

const LogoBlock = ({ image, withMargin = true }) => {
	const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();
	return (
		<div
			ref={ref}
			className={clsx(
				"LogoBlock",
				"relative",
				"h-[60px]",
				"flex items-center justify-center",
				{
					"mx-6 md:mx-6 lg:mx-6 xl:mx-6 2xl:mx-7.5 my-4": withMargin,
					"mb-7.5 md:mb-0": !withMargin,
				}
			)}
		>
			{image?.url && (
				<Image
					src={image.url}
					alt={image.title}
					width={image.width}
					height={image.height}
					layout="intrinsic"
					draggable={false}
					priority={true}
					className={clsx("LogoBlock__logo")}
				/>
			)}
		</div>
	);
};

export default LogoBlock;
